import toast from '@/components/ui/toast'
// import { getSalutations } from '~/utils/shop/general'
import { authLink, bffLink } from '~/constants'
import { getCurrentLangCode } from '~/utils/languages'
import { getI18nLanguageId } from '~/utils/languages'
import { useNewSettingsStore } from '~/store/newSettingsStore'

interface RegisterParams {
  email: string
  errorMessage: string
  firstName: string
  hCaptchaToken: string
  isSubscribedToNewsletter: boolean
  lastName: string
  password: string
}

interface User {
  firstName: string
  gender: string
  languageId?: number
  lastName: string
  phoneCode: string
  phoneNumber: string
  title: string
}

const useUserStore = defineStore('user', {
  state: () => ({
    addresses: [],
    askForGender: null as boolean | null,
    errorMessage: '',
    orders: null,
    // salutations: getSalutations(),
    user: {} as User,
  }),
  getters: {
    userFullName: (state) => `${state.user.firstName} ${state.user.lastName}`,
    masterCustomerId: (state) => {
      return state.user && state.user.masterCustomerIdentifier
    },
    countryId: () => {
      const settings = useNewSettingsStore()

      return settings.currentCountry?.value?.Id || 1
    },
  },
  actions: {
    resetUser() {
      this.user = null
    },
    setOrders(newValue) {
      this.orders = newValue
    },
    handleError(errorCode?: string) {
      const { translate } = useTranslations()
      this.errorMessage = translate('something-went-wrong', 'Something went wrong, please try again later')

      if (errorCode) {
        this.errorMessage = `(${errorCode}) ${message}`
      }

      console.error(message)
    },
    async getUser() {
      const { data, error } = await useFetch(`${bffLink}/v1/account/me`, {
        method: 'GET',
        credentials: 'include',
      })

      if (error || !data.value) {
        return this.handleError()
      }

      this.user = data.value
      return data.value
    },
    redirectToLogin() {
      navigateTo('/login')
    },
    handleLogout() {
      this.logout()
        .then(() => {
          this.redirectToLogin()
        })
        .catch((error) => {
          toast.error(error)
        })
    },
    async register(registerParams: RegisterParams) {
      const { firstName, lastName, email, password, isSubscribedToNewsletter, hCaptchaToken } = registerParams
      const { data, error } = await useFetch(`${authLink}/v1/register`, {
        method: 'POST',
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          password,
          isSubscribedToNewsletter,
          'h-captcha-response': hCaptchaToken,
          languageId: getI18nLanguageId(),
        }),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      })

      if (error || data.value?.status !== 200) {
        return handleError(data.value?.errorCode)
      }

      return true
    },
    async logout() {
      const { data, error } = await useFetch(`${authLink}/logout`, {
        method: 'GET',
        credentials: 'include',
      })

      if (error.value) {
        return this.handleError()
      }

      if (data.value && data.value.status === 200) {
        this.resetUser
        return
      }

      return data.value?.errorCode ? this.handleError(data.value.errorCode) : this.handleError()
    },
    async checkOrder({ orderNumber, email }: { orderNumber: string; email: string }) {
      const { data, error } = await useFetch(`/WebService.asmx/CheckOrder`, {
        method: 'POST',
        body: JSON.stringify({ orderNumber, email }),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      })

      if (error || data.value?.status !== 200) {
        return this.handleError()
      }

      return data.value.d || this.handleError()
    },
    async getOrders() {
      const { data, error } = await useFetch(`${bffLink}/v1/account/me/orders`, {
        method: 'GET',
        credentials: 'include',
      })

      if (error || data.value?.status !== 200) {
        return this.handleError()
      }

      this.setOrders(data.value)
    },
    async updateAccount(user: User) {
      const { data, error } = await useFetch(`${bffLink}/v1/account/me`, {
        method: 'PUT',
        body: {
          title: user.title,
          gender: user.gender,
          firstName: user.firstName,
          lastName: user.lastName,
          phoneCode: user.phoneCode,
          phoneNumber: user.phoneNumber,
          languageId: getCurrentLangCode(),
        },
      })

      if (error.value) {
        return this.handleRequestError()
      }

      if (data.value?.status === 200) {
        // const message: string = util.translate('account-updated', 'Your account has been updated')

        // alert({
        //   variant: 'success',
        //   message,
        // })

        return true
      } else if (data.value) {
        return this.handleRequestError(data.value.errorCode)
      }

      return false
    },
    async getAddresses() {
      try {
        const { data, error } = await useFetch(`${bffLink}/v1/account/me/addresses`, {
          method: 'GET',
          credentials: 'include',
        })

        if (error.value) {
          this.addresses = []
          return false
        }

        if (data.value && data.value.addresses && data.value.addresses.length > 0) {
          this.addresses = data.value.addresses
          return true
        } else {
          this.addresses = []
          return false
        }
      } catch (error) {
        this.addresses = []
        return false
      }
    },
    handleRequestError(errorCode?: string): boolean {
      // const message: string = util.translate(
      //   'account-update-failed',
      //   'Failed to update your account. Please try again.',
      // )

      // useAlert().$alert({
      //   variant: 'danger',
      //   message,
      // })

      if (errorCode) {
        console.error(`Error Code: ${errorCode}`)
      }

      return false
    },
    async getOrders() {
      const masterCustomerId = this.masterCustomerId
      const countryId = this.countryId

      try {
        const { data, error } = await useFetch('/api/account/orders-info', {
          method: 'POST',
          body: {
            countryId,
            masterCustomer: masterCustomerId,
          },
          headers: {
            'Content-Type': 'application/json',
          },
          baseURL: bffLink,
        })

        if (error.value) {
          throw error.value
        }

        if (data.value) {
          this.orders = data.value
          return true
        }

        return false
      } catch (error) {
        console.error('Error fetching orders:', error)
        return false
      }
    },
  },
})

export default useUserStore
